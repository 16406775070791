import {
  ref, onMounted, getCurrentInstance, watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  voucherDiscountUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import moment from 'moment'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import saveAs from 'file-saver'
import { isMobile } from '@/plugins/system/device'
import { fileWrite } from '@/plugins/system/filesystem'
import {
  environment
} from '@/utils'
import DialogScanSouvenir from '@/views/shared-components/dialog/DialogScanSouvenirCode.vue'
import DialogPrintSouvenir from '@/views/shared-components/dialog/DialogPrintSouvenirRedeem.vue'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default {
  name: 'VoucherDiscountV2',
  components: {
    DialogScanSouvenir,
    DialogPrintSouvenir
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const inputSearch = ref('') as any
    const route = useRoute()
    const router = useRouter()
    const isShowDialog = ref(false)
    const isShowDialogRedeem = ref(false)
    const isShowDialogPrintRedeem = ref(false)
    const currentTab = ref(0)
    const dataSource = ref([]) as any
    const dataDetail = ref(null)
    const myVoucherId = ref(null)
    const voucherCodeId = ref(null)
    const voucherData = ref(null)
    const filters = {
      search: '' as any,
      totalRecords: 0,
      page: 1,
      generateRefCode: 'false',
      top: $numbers.totalItemPerPage,
      select: '&$select=Id,Nama,Keterangan,UseLimit,IsActive,IsHidden,Thumbnail,ResetPeriod,Filename,GenerateRefCode',
      custom: 'GenerateRefCode eq false',
      // expand: '&$expand=MsVoucherCodes($select=Kode,IsAvailable;$filter=IsAvailable eq true),MsVoucherDetails($expand=VoucherPram($select=Nama,Tipe);$select=Id,VoucherGrupId,VoucherHdId,VoucherPramId,Nilai,NilaiGrup)'
      expand: '&$expand=MsVoucherCodes($expand=MsMyVouchers)'
    } as any

    const getDetails = async (id: any) => {
      const response = await voucherDiscountUseCase.getVoucherHdV2Detail(id)
      if (!response.error) {
        return response.result
      }
    }

    const getCodes = async (id: any) => {
      const response = await voucherDiscountUseCase.getVoucherHdV2Codes(id)
      if (!response.error) {
        console.log('response', response.result)
        return response.result
      }
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      dataSource.value = []
      filters.search = inputSearch.value
      const response = await voucherDiscountUseCase.getVoucherHdV2(filters)
      if (!response.error) {
        response.result.Data.map(async (x: any) => {
          const voucherDetails = await getDetails(x.Id)

          const voucherCodes = currentTab.value === 0 ? await getCodes(x.Id) : []
          // console.log('voucherDetails', voucherDetails)
          // console.log('promise', x)
          const obj = {
            ...x,
            Filename: x.Filename ? `${environment.getApiBase()}${x.Filename}` : require('@/assets/logo.png'),
            Thumbnail: x.Thumbnail ? `${environment.getApiBase()}${x.Thumbnail}` : require('@/assets/logo.png'),
            TanggalBerlaku: moment(voucherDetails.filter((fil: any) => fil.VoucherPramId === 12)[0]?.Nilai.split(',')[1]).format('DD MMMM YYYY'),
            VoucherCodes: voucherCodes
          }
          dataSource.value.push(obj)
        })
        console.log('dataSource', dataSource.value)
        filters.totalRecords = response.result.Count
      } else {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      }
      console.log('filters', filters)
      store.dispatch('hideLoading')
    }
    const activated = async (val: any) => {
      store.dispatch('showLoading')
      const data = {
        // Id: val.Id,
        IsActive: val.IsActive,
        IsHidden: false
      }
      const response = await voucherDiscountUseCase.changeIsActiveVoucherHd(val.Id, data)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorChangeStatus,
          group: 'bc',
          life: 1500
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: 'Berhasil merubah status aktif',
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }
    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          activated(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }
    const onPage = (evt: any) => {
      filters.page = evt.page + 1
      getAllData()
    }
    const redirectAdd = () => {
      router.push({
        name: 'master-voucher-diskon-add'
      })
    }
    const detailVoucher = async (val: any) => {
      // dataDetail.value = await val
      // isShowDialog.value = true
      const dataEnc = encryptDecriptMethods.encrypt(val.Id)
      router.push({
        name: 'master-voucher-diskon-edit',
        params: {
          idVoucher: dataEnc
        }
      })
    }

    const successCopy = () => {
      $toast.add({
        severity: 'success', detail: 'Berhasil dicopy', group: 'bc', life: 3000
      })
    }

    const errorCopy = () => {
      $toast.add({
        severity: 'error', detail: 'Gagal copy, mohon copy manual', group: 'bc', life: 3000
      })
    }

    const downloadText = async (id: any) => {
      const voucherCodes = await getCodes(id)
      const textTemp = voucherCodes.map((x: any) => x.Code)
      const name = 'kode.txt'
      let notif = null
      if (await isMobile()) {
        const file = await new Blob([textTemp.join('\n')], { type: 'text/plain' })
        await fileWrite(name, file)
        notif = 'Kode berhasil diunduh ke folder : DOCUMENTS'
        $toast.add({
          severity: 'success', detail: notif, group: 'bc', life: 5000
        })
      } else {
        saveAs(new Blob([textTemp.join('\n')], { type: 'text/plain' }), name)
        notif = 'Kode berhasil diunduh'
        $toast.add({
          severity: 'success', detail: notif, group: 'bc', life: 5000
        })
      }
    }

    const showRedeemDialog = () => {
      isShowDialogRedeem.value = true
    }

    const hideRedeemDialog = () => {
      isShowDialogRedeem.value = false
    }

    const showPrintRedeemDialog = () => {
      isShowDialogRedeem.value = false
      isShowDialogPrintRedeem.value = true
    }

    const hidePrintRedeemDialog = () => {
      isShowDialogPrintRedeem.value = false
    }

    const initVoucherCodeId = (val1: any, val2: any, val3: any) => {
      myVoucherId.value = val1
      voucherCodeId.value = val2
      voucherData.value = val3
    }

    watch(currentTab, (val: any) => {
      if (val === 0) {
        filters.generateRefCode = 'false'
        filters.page = 1
        getAllData()
      } else if (val === 1) {
        filters.generateRefCode = 'true'
        filters.page = 1
        getAllData()
      }
    })

    onMounted(() => {
      getAllData()
    })
    return {
      redirectAdd,
      inputSearch,
      getAllData,
      route,
      dataSource,
      changeIsActive,
      filters,
      onPage,
      isShowDialog,
      detailVoucher,
      dataDetail,
      successCopy,
      errorCopy,
      currentTab,
      downloadText,
      isShowDialogRedeem,
      showRedeemDialog,
      hideRedeemDialog,
      isShowDialogPrintRedeem,
      hidePrintRedeemDialog,
      showPrintRedeemDialog,
      myVoucherId,
      voucherCodeId,
      initVoucherCodeId,
      checkPermission,
      PermissionEnum,
      voucherData
    }
  }
}
