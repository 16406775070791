
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed,
  watch
} from 'vue'
import {
  voucherDiscountUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import QrScanner from '@/views/shared-components/barcode-scanner/QrScanner.vue'
import { debounce, isNull } from 'lodash'

export default {
  name: '',
  components: {
    QrScanner
  },
  props: {
    isShowDialog: {
      default: false
    }
  },
  emits: ['hideDialog', 'openPrintDialog', 'initVoucherCodeId', 'rescan'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props) as any
    const showDialog = propertiesProps.isShowDialog
    const router = useRouter()
    const souvenirCode = ref(null)
    const store = useStore()
    const myVoucherId = ref(null)
    const voucherCodeId = ref(null)
    const voucherData = ref(null)
    const hideDialogRedeem = () => {
      // dialogDetailResi.value = false
      emit('hideDialog')
    }
    const openPrintDialog = () => {
      // dialogDetailResi.value = false
      emit('openPrintDialog')
    }
    const initVoucherCodeId = () => {
      console.log('test', myVoucherId.value, voucherCodeId.value, voucherData.value)
      emit('initVoucherCodeId', myVoucherId.value, voucherCodeId.value, voucherData.value)
    }
    const RedeemCode = async () => {
      try {
        store.dispatch('showLoading')
        if (!isNull(souvenirCode.value)) {
          const response = await voucherDiscountUseCase.redeemByGuid(souvenirCode.value)
          if (!response.error) {
            const result = response.result.Data[0]
            if (result.IsUsed) {
              hideDialogRedeem()
              $toast.add({
                severity: 'error',
                detail: 'Gagal! Voucher sudah digunakan',
                group: 'bc',
                life: 1500
              })
              store.dispatch('hideLoading')
            } else {
              myVoucherId.value = result.Id
              voucherCodeId.value = result.VoucherCodeId
              voucherData.value = result
              initVoucherCodeId()
              openPrintDialog()
            }
          } else {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 1500
            })
            hideDialogRedeem()
          }
        }
        store.dispatch('hideLoading')
      } catch (err) {
        store.dispatch('hideLoading')
      }
    }
    const openHistoryRedeemSouvenir = () => {
      router.push({
        name: 'master-voucher-diskon-history-redeem-souvenir'
      })
    }

    const onDetectScanner = (result: any) => {
      console.log(result)
      if (result?.data) {
        // eslint-disable-next-line prefer-destructuring
        souvenirCode.value = result.data
      }
    }

    watch(() => souvenirCode.value, debounce(RedeemCode, 300))

    return {
      souvenirCode,
      showDialog,
      hideDialogRedeem,
      RedeemCode,
      openPrintDialog,
      initVoucherCodeId,
      openHistoryRedeemSouvenir,
      onDetectScanner
    }
  }
}
