
import QrScanner from 'qr-scanner'
import { debounce } from 'lodash'
import {
  ref,
  getCurrentInstance,
  onMounted,
  defineComponent,
  onBeforeUnmount,
  toRefs,
  watch
} from 'vue'

export default defineComponent({
  emits: ['onDetect'],
  setup(props: any, { emit }) {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    let qrScanner = null as any
    const refVideo = ref()
    const resultScan = (result: any) => {
      emit('onDetect', result)
      qrScanner.stop()
    }

    const initialize = async () => {
      qrScanner = new QrScanner(refVideo.value, (result: any) => resultScan(result), {
        highlightScanRegion: true,
        highlightCodeOutline: true,
      })

      const checkCamera = await QrScanner.hasCamera()
      if (checkCamera) {
        qrScanner.start()
      } else {
        $toast.add({
          severity: 'error', detail: 'Camera tidak ditemukan/didukung', group: 'bc', life: 3000
        })
      }
    }

    onMounted(() => {
      initialize()
    })

    onBeforeUnmount(() => {
      qrScanner.destroy()
    })

    return {
      qrScanner,
      refVideo
    }
  }
})
